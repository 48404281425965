import {
  getFullName,
  getTradeNameSafe,
  removeOffsetDate,
  useCreateAditionalActivity,
  useAdditionalActivityFindOne,
  useAdditionalActivityUpdateOne,
  useMissionFindOne,
  queryClient,
  dateIsWithinInterval,
  getFormulaDutyFormulaAmount,
  getHumanDateMonthAndYear,
  round,
  ADDIONAL_FILES_NUMBER,
} from '@commons';
import {
  EadditionalActivityStatus,
  IJoinedMission,
  IStandByDutyLine,
  EadditionalActivityType,
  IAdditionalActivityStandByDutyData,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { EuroIcon } from 'components/icons';
import TextInformation from 'components/TextInformation';
import {
  Box,
  Button,
  Cell,
  Flex,
  Grid,
  LabelField,
  Link,
  SpinnerBox,
  Text,
  TextArea,
  FormControl,
  ColorTextBox,
  Divider,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks';
//@ts-ignore
import jsonQ from 'jsonq';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';
import { AddDutyLine } from './AddDutyLine';
import { FileListControlled } from 'components/ui';

type CreateStandByDutyProps = {
  month: Date;
  missionRef: string;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
  onResolve?: (params: any) => void;
};

const grid12 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
type Line = IStandByDutyLine & {
  lock?: boolean;
  key?: string;
};
const defaultLine = {
  lock: false,
};
export const CreateStandByDutyForm: React.FC<
  React.PropsWithChildren<CreateStandByDutyProps>
> = ({ month, missionRef, method, uuid, onResolve }) => {
  const { data: activity, isFetching: isFetchingAddtional } =
    useAdditionalActivityFindOne(uuid);
  const {
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      //@ts-ignore
      attachments: activity?.attachments,
    },
  });
  const existingAttachments = activity?.attachments;

  const { data: mission, isFetching: isFetchingMission } =
    useMissionFindOne(missionRef);
  const [providerComment, setProviderComment] = useState(
    activity?.providerComment
  );
  const showMessage = useShowMessage();
  const standByDutyLines: Line[] = activity?.standByDutyLines || [];
  const [lines, setLines] = useState<Line[] | []>(
    method === 'UPDATE' ? standByDutyLines : []
  );
  const [loading, setLoading] = useState(false);
  const [onCreate, setOnCreate] = useState(method === 'CREATE' ? true : false);
  const [onUpdateLine, setOnUpdateLine] = useState<string | false>(false);
  const missionStandByDutyFormulas = mission?.standByDutyFormulas || [];

  //const onEdit = lines?.findIndex((l: Line) => !l?.lock) !== -1;
  // TODO FILTRE BY DATE AND ACTIVE !!!
  const avalaibleFormulas = [...missionStandByDutyFormulas]?.map((f: any) => {
    return {
      key: f?.uuid,
      label: `${f?.name}`,
      value: f?.uuid,
      data: f,
    };
  });

  const handleCreateOrUpdateLine = (line: Line) => {
    const updatedLines = jsonQ.clone(lines);

    const index = lines?.findIndex((l: Line) =>
      line?.uuid ? l?.uuid === line?.uuid : l?.key === line?.key
    );

    if (index !== -1) {
      updatedLines[index] = line;
    } else {
      updatedLines?.push(line);
    }

    setOnUpdateLine(false);
    setLines(updatedLines);
    setOnCreate(false);
  };

  const isAttachmentsRequired = (availablesFormulas: any, lines: any) => {
    const linesUuids = lines?.map((l: any) => {
      return l?.standByDutyFormulaLineUuid;
    });

    const formulasUsed = availablesFormulas?.filter((f: any) =>
      linesUuids?.includes(f.key)
    );
    for (const elt of formulasUsed) {
      if (elt.data.shouldJoinAttachment) return true;
    }
    return false;
  };
  const handleDeleteLine = (line: Line) => {
    const updatedLines = jsonQ.clone(lines);
    const index = lines?.findIndex(
      (l: Line) =>
        (line?.uuid && l?.uuid === line?.uuid) || l?.key === line?.key
    );
    updatedLines?.splice(index, 1);
    setOnUpdateLine(false);
    setLines(updatedLines);
    setOnCreate(false);
  };
  const totalAmount = () => {
    let total = 0;
    lines?.forEach(
      l => (total = total + getFormulaDutyFormulaAmount(l, 'PROVIDER'))
    );
    return round(total);
  };
  const { mutateAsync: createAdditionalActivity } =
    useCreateAditionalActivity();
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();

  const onSubmit = async (saveLater: boolean) => {
    const formValues = getValues();
    setError('attachments', { message: undefined });
    //@ts-ignores
    const attachments = formValues?.attachments;
    if (!attachments?.length) {
      if (isAttachmentsRequired(avalaibleFormulas, lines)) {
        setError('attachments', {
          message: 'Au moins un justificatif est requis',
        });
        return;
      }
    }

    const submitValues = {
      saveAndFinishLater: saveLater,
      missionReference: mission?.reference as string,
      type: EadditionalActivityType?.STAND_BY_DUTY,
      providerComment,
      month: month,
      attachments,
      standByDuties: lines?.map(l => {
        return {
          ...l,
          lock: undefined,
          key: undefined,
        };
      }) as IAdditionalActivityStandByDutyData[],
    };
    try {
      setLoading(true);
      if (method === 'CREATE') {
        await createAdditionalActivity(submitValues);
      }
      if (method === 'UPDATE') {
        const removeStandByDutyLines: string[] | [] = [];
        standByDutyLines?.forEach(sd => {
          if (!lines?.find(l => l?.uuid === sd?.uuid)) {
            removeStandByDutyLines?.push(sd.uuid as never);
          }
        });

        const addAttachments: any[] = [];
        const removeAttachments: any[] = [];
        const existingAttachmentsFileNames =
          existingAttachments?.map((f: any) => f.fileName) || [];

        const currentAttachmentsFileNames =
          attachments?.map((file: any) => file.fileName) || [];

        attachments?.forEach((sd: any) => {
          if (!existingAttachmentsFileNames?.includes(sd?.fileName)) {
            addAttachments?.push(sd);
          }
        });
        existingAttachments?.forEach((sd: any) => {
          if (!currentAttachmentsFileNames?.includes(sd?.fileName)) {
            removeAttachments?.push(sd);
          }
        });

        await updateAdditionalActivity({
          uuid: uuid,
          ...submitValues,
          removeStandByDutyLines,
          status: EadditionalActivityStatus.TO_BE_SUBMITTED,
          addAttachments,
          removeAttachments,
        });
      }
      queryClient.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      queryClient.refetchQueries({ queryKey: ['ActivitiesReport'] });
      showMessage(
        'success',
        `Votre prestation complémentaire à été ${
          method === 'UPDATE' ? 'mise à jour' : 'créée'
        }`
      );
      if (onResolve && typeof onResolve === 'function') {
        onResolve(true);
      }
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (activity) {
      setProviderComment(activity?.providerComment);
    }
  }, [activity]);
  useEffect(() => {
    if (method === 'UPDATE' && uuid) {
      if (activity && activity?.standByDutyLines?.length) {
        setLines(
          activity?.standByDutyLines?.map((sd: any) => ({
            ...sd,
            lock: true,
          }))
        );
      }
    }
  }, [activity, method, uuid]);
  if (isFetchingAddtional || isFetchingMission) {
    return <SpinnerBox />;
  }
  if (missionStandByDutyFormulas?.length === 0) {
    return (
      <Box width={1 / 1}>
        <Header tabs={false} height={120}>
          <Text variant="h1">Déclarer une activité</Text>
        </Header>
        Vous ne pouvez pas créer de prestation complémentaire sur cette mission.
      </Box>
    );
  }
  const isEditable =
    !activity?.status ||
    activity?.status === EadditionalActivityStatus?.TO_BE_FILLED ||
    activity?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED ||
    activity?.status === EadditionalActivityStatus?.REJECTED;
  const getStatusData = (
    status: EadditionalActivityStatus
  ): {
    variantColor: 'grey' | 'warning' | 'success' | 'error';
    statusText: string;
    textColor: string;
  } => {
    switch (status) {
      case EadditionalActivityStatus.TO_BE_FILLED:
        return {
          variantColor: 'grey',
          statusText: 'A saisir',
          textColor: Theme.colors.grey.default,
        };
      case EadditionalActivityStatus.TO_BE_SUBMITTED:
        return {
          variantColor: 'grey',
          statusText: 'A soumettre',
          textColor: Theme.colors.grey.default,
        };
      case EadditionalActivityStatus.TO_BE_VALIDATED:
        return {
          variantColor: 'warning',
          statusText: 'En attente de validation',
          textColor: Theme.colors.warning.default,
        };
      case EadditionalActivityStatus.VALIDATED:
        return {
          variantColor: 'success',
          statusText: `Validé`,
          textColor: Theme.colors.success.default,
        };
      case EadditionalActivityStatus.REJECTED:
        return {
          variantColor: 'error',
          statusText: `Refusé`,
          textColor: Theme.colors.error.default,
        };
      case EadditionalActivityStatus.ARCHIVED:
        return {
          variantColor: 'grey',
          statusText: `Archivé`,
          textColor: Theme.colors.grey.default,
        };
      default:
        return {
          variantColor: 'grey',
          statusText: 'A saisir',
          textColor: Theme.colors.grey.default,
        };
    }
  };
  const statusData = getStatusData(
    activity?.status as EadditionalActivityStatus
  );
  return (
    <Box width={1 / 1}>
      <Header tabs={false} height={120}>
        <Flex alignItems="center" alignContent="center">
          <Text variant="h1">
            {method === 'UPDATE' ? 'Modifier' : 'Déclarer'} une prestation
            complémentaire
          </Text>
          {method === 'UPDATE' && (
            <ColorTextBox variantColor={statusData?.variantColor} ml={5}>
              {statusData?.statusText}
            </ColorTextBox>
          )}
        </Flex>
      </Header>
      {activity?.rejectReason &&
        activity?.status === EadditionalActivityStatus?.REJECTED && (
          <div style={{ padding: 5 }}>
            <TextInformation variant="error" width={1 / 1} mb={10}>
              {`Cette prestation complémentaire été refusé le ${new Date(
                activity?.statusChangedAt as Date
              ).toLocaleDateString()} pour cause de : ${activity?.rejectReason}`}
            </TextInformation>
          </div>
        )}
      <Box>
        <Grid cols={12} gap="10px">
          <Cell x-span={grid12}>
            <Flex
              height={'100px'}
              p={25}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              backgroundColor="#edf3ff"
              borderRadius="8px"
            >
              <LabelField
                underline
                label="Période"
                value={getHumanDateMonthAndYear(month)}
              />
              <LabelField
                underline
                ml={5}
                label="Référence mission"
                value={mission?.displayReference}
              />
              <LabelField
                underline
                ml={5}
                label="Client"
                value={
                  mission && getTradeNameSafe(mission?.customer?.establishment)
                }
              />
              <LabelField
                underline
                ml={5}
                label="Intervenant"
                value={getFullName(mission?.provider?.contractor)}
              />
            </Flex>
          </Cell>
        </Grid>
        {lines
          ?.sort((a: Line, b: Line) => Number(b.lock) - Number(a.lock))
          ?.map((l: Line, index) => (
            <>
              <AddDutyLine
                key={`line_${l?.uuid || l?.key}`}
                isEditable={isEditable}
                readOnly={onUpdateLine === l?.uuid ? false : l?.lock}
                line={l}
                mission={mission as IJoinedMission}
                onCreateOrUpdateLine={(line: Line) =>
                  handleCreateOrUpdateLine(line)
                }
                unLockToUpdate={(line: Line) =>
                  setOnUpdateLine(line?.uuid as string)
                }
                onDeleteLine={(line: Line) => handleDeleteLine(line)}
                avalaibleFormulas={avalaibleFormulas}
              />
              {index + 1 !== lines?.length && <Divider />}
            </>
          ))}
        {onCreate && (
          <>
            <AddDutyLine
              key={`line_add`}
              readOnly={false}
              //@ts-ignore
              line={defaultLine}
              mission={mission as IJoinedMission}
              onCreateOrUpdateLine={(line: Line) =>
                handleCreateOrUpdateLine(line)
              }
              onDeleteLine={(line: Line) => handleDeleteLine(line)}
              avalaibleFormulas={avalaibleFormulas?.filter((f: any) => {
                // DELETE DEACTIVATED FORMULA AND NOT IN INTERVAL OF DECLARATION ON CREATE !
                return (
                  dateIsWithinInterval(
                    removeOffsetDate(month as Date),
                    f?.data?.validityStart,
                    f?.data?.validityEnd
                  ) && f?.data?.active
                );
              })}
            />
            <Divider />
          </>
        )}
        {!onCreate && isEditable && (
          <Box mt={20}>
            <Link
              onClick={() => setOnCreate(true)}
              iconLeft={<AddIcon fill={Theme?.colors?.primary?.default} />}
            >
              Ajouter une prestation complémentaire
            </Link>
          </Box>
        )}
        <Box mt={25}>
          <Text variant="b" textAlign={'left'}>
            Justificatifs
          </Text>

          <FormControl
            label=""
            errorMessage={errors?.attachments?.message}
            mt={-20}
          >
            <FileListControlled
              control={control}
              maxFile={lines.length + ADDIONAL_FILES_NUMBER}
              name="attachments"
              missionRef={mission?.reference}
              isDisabled={!isEditable}
              actionName={'Importer un justificatif'}
              errorMessage={'eror test message'}
              accept=".pdf"
              defaultValue={activity?.attachments as any}
              onChangeAction={(e: any) => {
                if (e?.length) setError('attachments', { message: undefined });
              }}
            />
          </FormControl>
        </Box>
        <Grid cols={12} mt={20} gap="10px">
          <Cell x-span={grid12}>
            <FormControl label="Commentaire envoyé">
              <TextArea
                isDisabled={!isEditable}
                minRows={3}
                name={'providerComment'}
                value={providerComment}
                onChange={e => setProviderComment(e?.target?.value)}
                placeholder={
                  isEditable
                    ? 'Ajouter un commentaire destiné aux équipes Freelance.com'
                    : 'N/A'
                }
              />
            </FormControl>
          </Cell>
        </Grid>
        <Flex width={1 / 1} justifyContent="flex-end" mt={10}>
          <Text
            p={10}
            variant="h1"
            color="#009933"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(0, 153, 51, 0.1)"
            fontWeight={500}
          >
            Montant total HT : {totalAmount()}
            <EuroIcon fill="#009933" />
          </Text>
        </Flex>
        <Flex mt={10} justifyContent="flex-end">
          {(!activity?.status ||
            activity?.status === EadditionalActivityStatus?.TO_BE_FILLED ||
            activity?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED ||
            activity?.status === EadditionalActivityStatus?.REJECTED) && (
            <Button
              isLoading={loading}
              isDisabled={lines?.length < 1}
              variant="ghost"
              ml={10}
              onClick={() => onSubmit(true)}
            >
              Enregistrer
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

CreateStandByDutyForm.displayName = 'CreateStandByDutyForm';

export default CreateStandByDutyForm;
