import {
  checkFormEmail,
  getMe,
  useAmplifyLogin,
  useUserUpdateOne,
  queryClient,
  checkAuthValid,
  getCognitoCookies,
  cleanSession,
  checkUserHasRole,
  ERoles,
  REACT_APP_COGNITO_CLIENT_ID,
} from '@commons';
import { ConnexionIcon, LogoConnecteed, LogoFcomIcon } from 'components/icons';
import { showCGUModal } from 'components/modals/CGUModal';
import { showContactUsModal } from 'components/modals/ContactUs';
import { showDialogModal } from 'components/modals/DialogModal';
import { showForgetPasswordModal } from 'components/modals/ForgetPassword';
import {
  Box,
  Button,
  Cell,
  Container,
  Divider,
  Flex,
  FormControl,
  Grid,
  Input,
  Link,
  Text,
} from 'components/ui';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { useIntercom } from 'react-use-intercom';
import { useShowMessage } from 'hooks';
import { useTranslationFormat } from 'hooks/useTranslateFormat';

type FormValues = {
  email: string;
  password: string;
};

export const LoginPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [checkCookies, setCheckCookies] = React.useState(true);
  const [previousCookies, setPreviousCookies] =
    React.useState(getCognitoCookies());
  const t = useTranslationFormat();
  const { boot } = useIntercom();
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Connexion`,
      path: '/login',
    },
  ]);
  boot();
  const [isMobilCheck, setIsMobileCheck] = React.useState(false);
  const width = window?.innerWidth;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  //const { mutateAsync: login, isLoading } = useAmplifyLogin();
  const { mutateAsync: login, isPending } = useAmplifyLogin();
  const history = useHistory();
  const { mutateAsync: updateUser } = useUserUpdateOne();
  const showMessage = useShowMessage();
  const onSubmit = async (data: FormValues) => {
    try {
      //Clean session before connect
      await cleanSession();
      await login(data);
      const me = localStorage?.token ? await getMe() : false;
      if (me) {
        queryClient.setQueryData(['me'], me);
      }
      if (me && !me?.termsOfUseAccepted) {
        await showCGUModal({
          beforeValidation: async () => {
            try {
              await updateUser({
                id: me?.cognitoUserId,
                user: {
                  termsOfUseAccepted: true,
                  isConfirmed: true,
                },
              });
              history.push('/');
            } catch (e) {
              //console.log('catch', e);
            }
          },
        });
      } else {
        history.push('/');
      }
    } catch (error: any) {
      //console.log('ERROR LOGIN', error);
      if (error?.message?.includes('There is already a signed in user')) {
        showMessage('error', 'There is already a signed in user');
        cleanSession();
      } else {
        showMessage('error', t('errors:INSI-AUTH-001'));
      }
    }
  };

  React.useEffect(() => {
    if (!isMobilCheck && width && width < 840) {
      setTimeout(
        () =>
          showDialogModal({
            title: 'Accès mobile',
            text: 'Nous avons détecté que vous utilisiez Connecteed via votre mobile. Veuillez noter que le site n’est actuellement pas optimisé pour ce support. Pour une expérience optimale, nous vous recommandons de vous reconnecter via un ordinateur.',
            confirmLabel: "D'accord",
          }).then(() => setIsMobileCheck(true)),
        1000
      );
    }
  }, [width, isMobilCheck]);
  const checkLogin = async () => {
    //console.log('<--- CHECK LOGIN START --->');
    const currentCookies = getCognitoCookies();
    if (currentCookies?.length > 0) {
      if (currentCookies !== previousCookies) {
        setPreviousCookies(currentCookies);
        setCheckCookies(false);
        const isAuth = checkAuthValid();
        const me = await getMe();
        // console.log('<--- CHECK LOGIN DATA --->', {
        //   currentCookies,
        //   me,
        //   isAuth,
        // });

        if (me) {
          queryClient.setQueryData(['me'], me);
        }
        if (isAuth && me) {
          if (
            checkUserHasRole(me, ERoles.PROVIDER_CONTACT) ||
            checkUserHasRole(me, ERoles.PROVIDER_RESPONSIBLE)
          ) {
            let cookieKey = `CognitoIdentityServiceProvider.${REACT_APP_COGNITO_CLIENT_ID}.${localStorage?.cognitoUserId}.cookieFrom`;
            document.cookie = `${cookieKey}=PROVIDER; domain=freelance.com`;
            history?.push('/dashboard');
          } else {
            showMessage('error', t('errors:INSI-AUTH-001'));
            cleanSession();
          }
        }
      }
    }
    //console.log('<--- CHECK LOGIN END --->');
  };
  //check cookies login
  React.useEffect(() => {
    if (checkCookies) {
      const intervalId = setInterval(() => {
        checkLogin();
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [previousCookies]);
  return (
    <Flex
      display="inline-flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Container>
        <Grid
          style={{ height: '100vh', maxHeight: '900px', maxWidth: '1440px' }}
          cols={12}
        >
          <Cell x-span={{ xs: '6' }} placeSelf="center">
            <Box>
              <Text variant="h1" fontSize={32} color={'#000000'}>
                content de vous revoir sur
              </Text>
              <Text variant="h1" fontSize={32} mb={40}>
                connecteed
              </Text>
              <Box position="absolute" top={45} left={'46%'}>
                <LogoConnecteed />
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  required
                  label="Email"
                  errorMessage={errors?.email?.message}
                >
                  <Input
                    isFullWidth
                    type="email"
                    id="email"
                    {...register('email', {
                      //required: 'Ce champ est obligatoire',
                      validate: (value: any) => checkFormEmail(value),
                    })}
                  />
                </FormControl>
                <FormControl
                  required
                  label="Mot de passe"
                  errorMessage={errors?.password?.message}
                >
                  <>
                    <Input
                      isFullWidth
                      type="password"
                      id="password"
                      {...register('password', {
                        required: 'Ce champ est obligatoire',
                      })}
                    />
                    <Link mt={10} onClick={() => showForgetPasswordModal()}>
                      Mot de passe oublié ?
                    </Link>
                  </>
                </FormControl>
                <Button mb={10} type="submit" isLoading={isPending}>
                  Se connecter
                </Button>
              </form>
              <Box mt={40}>
                <Divider />
              </Box>
              <Text variant="span" color={'#819CAD'}>
                Vous avez besoin d’aide ?{' '}
                <Link onClick={() => showContactUsModal()}>Contactez-nous</Link>
              </Text>
            </Box>
          </Cell>
          <Cell x-span={{ xs: '6' }}>
            <ConnexionIcon
              style={{
                height: '100vh',
                maxHeight: '900px',
                maxWidth: '1440px',
              }}
              width={'100%'}
            />
            <Box
              position="relative"
              bottom={130}
              height={0}
              left={'60%'}
              width={'100px'}
            >
              <LogoFcomIcon fontSize={100} />
            </Box>
          </Cell>
        </Grid>
      </Container>
    </Flex>
  );
};

export default LoginPage;
